<template>
  <div class="layout-footer">
    <!-- Manejo de Versiones de la Aplicación: TILUCHI
		1 =>  Identifica los cambios generales y la inclusión de nuevos módulos al SOFTWARE.
		2 =>  Identifica la inclusión de nuevas funcionalidades a módulos existentes.
		3 =>  Identifica la solución a errores para permitir el correcto funcionamiento del SOFTWARE. -->

    <a href="https://sitbolivia.com/" target="_blank"
      ><strong> SIT-BOLIVIA VERSI&Oacute;N :1.1.60 </strong></a
    >
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  /* methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			}
		}, */
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.layout-footer a {
  color: #002236;
  text-decoration: none;
}

.layout-footer a:hover {
  text-decoration: underline;
}
</style>
